<div class="topbar">
  <div class="mr-auto">
    <div class="logoContainer">
      <div class="logo">
        <img src="assets/images/logo-long.png" class="menu-logo" />
      </div>
    </div>
    <!-- <cui-topbar-search></cui-topbar-search> -->
  </div>
  <div *ngIf="env">
    <div class="environment-badge {{env}}">{{env}}</div>
  </div>
  <!-- <div class="mr-4 d-none d-sm-block">
    <cui-topbar-actions></cui-topbar-actions>
  </div> -->
  <div>
    <cui-topbar-user-menu></cui-topbar-user-menu>
  </div>
</div>