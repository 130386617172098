import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class settingsService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getTimezoneList(): Observable<any> {
    return this.http.get(`Identity/Timezone`)
  }
  getCurrentTimezone(): Observable<any> {
    return this.http.get(`Identity/System/Timezone`)
  }
  updateTimezone(data): Observable<any> {
    return this.http.patch(`Identity/Update/Timezone`, {
      TimezoneAbbreviation: data,
    })
  }
  updateIP(): Observable<any> {
    return this.http.patch(`Identity/EnableDisableIPChecking`, {})
  }
  getIPAddress(): Observable<any> {
    return this.http.get(`Identity/GetAllAllowedIPAddress`)
  }
  getCurrentIP(): Observable<any> {
    return this.http.get(`Identity/IPAddressSetting`)
  }
  updateSelfIP(): Observable<any> {
    return this.http.patch(`Identity/EnableDisableSelfIPWhitelisting`, {})
  }
  checkValidIp(params): Observable<any> {
    return this.http.get(`Identity/CheckIfIPIsAllowedToAccess/${params}`)
  }
  addIPAddress(data): Observable<any> {
    return this.http.post(`Identity/AddIPAddressWhitelist`, {
      IPAddress: data.ip,
      Description: data.desc,
    })
  }
  updateIPAddress(data): Observable<any> {
    return this.http.post(`Identity/UpdateIPAddressWhitelist`, {
      IPAddress: data.ip,
      Description: data.desc,
      Id: data.id,
      Status: data.stat,
    })
  }
  updateIPStatus(ip): Observable<any> {
    return this.http.patch(`Identity/UpdateIPAddressStatus`, ip)
  }
  deleteIPAddress(ip): Observable<any> {
    return this.http.delete(`Identity/DeleteIPAddress/${ip}`)
  }
  getNotificationSettings(): Observable<any> {
    return this.http.get(`Identity/SendgridSetting`)
  }
  updateNotificationSettings(params): Observable<any> {
    return this.http.patch(`Identity/SendgridSetting`, params)
  }
  getAuthSettings(): Observable<any> {
    return this.http.get(`Identity/ListLoginSetting`)
  }
  updateAuthSettings(params): Observable<any> {
    return this.http.post(`Identity/UpdateLoginSettings`, params)
  }
  savePointLits(params): Observable<any> {
    return this.http.post(`Identity/AddRemovePerimeter`, params)
  }
  getPointLits(): Observable<any> {
    return this.http.get(`Identity/Perimeter`)
  }
  deletePerimeter(): Observable<any> {
    return this.http.delete(`Identity/ResetPerimeter`)
  }
  updateGeo(): Observable<any> {
    return this.http.patch(`Identity/EnableDisableGeoFence`, {})
  }
  getGeoStat(): Observable<any> {
    return this.http.get(`Identity/GetGeofenceSetting`)
  }
  getCutOff(): Observable<any> {
    return this.http.get(`Identity/PropertyCutoffSetting`)
  }
  updatetCutOff(params): Observable<any> {
    return this.http.patch(`Identity/PropertyCutoffSetting`, params)
  }
  getUnderwritingVersions(): Observable<any> {
    return this.http.get(`Identity/GetVersion`)
  }
  getCurrentCheckPayment(): Observable<any> {
    return this.http.get(`Identity/GetDefaultCheckSetting`)
  }
  updateCheckPayment(data): Observable<any> {
    return this.http.post(`Identity/CheckAutoPostSetting`, {
      value: data,
    })
  }
}
