import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class reportService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getReportPlayerActivity(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/Report/Search/PlayerActivity${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/Report/PlayerActivity${params}`)
    }
  }
  getReportLimitActivity(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/Report/Search/LimitActivity${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/Report/LimitActivity${params}`)
    }
  }
  getReportFeeActivity(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/Report/Search/FeeActivity${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/Report/FeeActivity${params}`)
    }
  }
  getReportPaymentActivity(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      if (data.payload.api == 'All') data.payload.api = 'Search/PaymentActivity'
      else data.payload.api = 'Payment/Search/' + data.payload.api
      return this.http.post(`Dashboard/Report/${data.payload.api}${params}`, data.payload.options)
    } else {
      if (data.payload.api == 'All') data.payload.api = 'PaymentActivity'
      else data.payload.api = 'Payment/' + data.payload.api
      return this.http.get(`Dashboard/Report/${data.payload.api}${params}`)
    }
  }
  getReportScoringFee(data?): Observable<any> {
    // if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    return this.http.get(`Dashboard/Report/ScoringFeeActivity`)
  }
  getReportProgramFee(data?): Observable<any> {
    // if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    return this.http.get(`Dashboard/Report/ProgramFeeActivity`)
  }
  getAgingReport(data, agingBy): Observable<any> {
    return this.http.get(
      `Dashboard/${
        agingBy == 'm'
          ? 'MonthlyAgingReport'
          : agingBy == 'w'
          ? 'AgingReport'
          : 'MarkerOriginationDate'
      }/${data}`,
    )
  }
  getAgingPlayerDetails(data?): Observable<any> {
    let params = `/${data.date}`
    if (data.id) params += `/${data.id}`
    return this.http.get(
      `Dashboard/${data.type == 'o' ? 'OriginationDateAgingDetails' : 'AgingSummary'}` + params,
    )
  }
  getReportCWAEnableDisable(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchEnableDisableReport${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/CWAEnableDisableReport${params}`)
    }
  }
  getReportLimitAdjustment(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchLimitAdjustment${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/GetLimitAdjustment${params}`)
    }
  }
  getReportFee(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchMTFees${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/GetMTFees${params}`)
    }
  }
  getReportMTSummary(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(
        `Dashboard/SearchMarkertraxSummaryReport${params}`,
        data.payload.options,
      )
    } else {
      return this.http.get(`Dashboard/GetMarkertraxSummaryReport${params}`)
    }
  }
  getReportUnderwriting(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchPlayerUnderwriting${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/GetPlayerUnderWriting${params}`)
    }
  }
  getReportCwaDetail(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchMTXDetailReport${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/CWADetailReport${params}`)
    }
  }
  getReportCwaAdjustment(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchAdjustment${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/Adjusments${params}`)
    }
  }
  getPatronCwaDetails(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    let d = data.payload.options
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchPatronCWADetailReport${params}`, data.payload.options)
    } else {
      return this.http.get(
        `Dashboard/PatronCWADetailsReport/${data.payload.startdate}/${data.payload.enddate}/${params}`,
      )
    }
  }
  returnCheck(data?): Observable<any> {
    return this.http.post(`Dashboard/cancel/pending/payment`, data)
  }
  processCheck(id, data?): Observable<any> {
    return this.http.post(`Dashboard/PostPendingCheckPayment/${id}`, data)
  }
  getProgManagementFeesReport(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    let d = data.payload.options
    // if (data.payload.options) {
    // return this.http.post(`Dashboard/SearchAdjustment${params}`, data.payload.options)
    return this.http.get(`Dashboard/pmfV2/${d.startdate}/${d.enddate}`)
    // } else {
    //   return this.http.get(`Dashboard/pmfV2`)
    // }
  }
  releaseVersion(): Observable<any> {
    return this.http.get(`Dashboard/Latest/Build`)
  }
  getPatronLiability(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(
        `Dashboard/SearchMarkertraxSummaryReport${params}`,
        data.payload.options,
      )
    } else {
      return this.http.get(`Dashboard/GetMarkertraxSummaryReport${params}`)
    }
  }
  getReportHistory(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/SearchAllHistoryReport${params}`, data.payload.options)
    } else {
      return this.http.get(`Dashboard/MTXAllHistoryReport${params}`)
    }
  }

  getActivitySummary(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    let d = data.payload.options
    if (data.payload.options) {
      //   return this.http.get(`Dashboard/SearchActivitySummaryReport/${d.startdate}/${d.enddate}${params}`)
      return this.http.post(`Dashboard/SearchActivitySummaryReport${params}`, data.payload.options)
    } else {
      return this.http.get(
        `Dashboard/MTXActivitySummaryReport/${d.startdate}/${d.enddate}${params}`,
      )
    }
  }

  getReportGGR(data?): Observable<any> {
    let params = ''
    if (data.payload) params = `/${data.payload.limit}/${data.payload.page}`
    if (data.payload.options) {
      return this.http.post(`Dashboard/Report/GrossGamingRevenue${params}`, data.payload.options)
    } else {
      return this.http.post(`Dashboard/Report/GrossGamingRevenue${params}`, {})
    }
  }
}
